'use client';

import { MEDIA_LARGE, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import { styled } from 'styled-components';
import { H2, P } from '../ui';
import { Stat } from '../ui/stat';

const Article = styled.div`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
  max-height: fit-content;
  @media (min-width: ${MEDIA_LARGE}px) {
    position: sticky;
    top: calc(30% - 86.5px);
  }
`;

const Container = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    align-items: center;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Section = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionFourChapterFour: React.FC<{
  header: string | null;
  description: string | null;
  stats:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFourSectionFourRedStats';
        percentage: number | null;
        description: Array<{
          __typename?: 'ModulesFlexibleModulesPrChFourSectionFourRedStatsDescription';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
  redHeader: string | null;
}> = ({ header, description, redHeader, stats }) => {
  return (
    <Section>
      <Container>
        <Article>
          <H2>{header}</H2>
          <P>{description}</P>
        </Article>
        <Stat color="red" stats={stats} header={redHeader} />
      </Container>
    </Section>
  );
};
