'use client';

import { MEDIA_LARGE, NEUTRAL_WHITE, RED_700, SPACING_10, SPACING_5 } from '@mnd-frontend/ui';
import { Fragment } from 'react';
import { styled } from 'styled-components';
import { P, Tag } from '../ui';

const Description = styled.div`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
`;

const Overflow = styled.aside`
  top: -0.75rem;
  position: absolute;
`;

const Wrapper = styled.div`
  position: relative;
  background-color: ${NEUTRAL_WHITE};
  border-radius: 0px;
  padding: ${SPACING_5};

  @media (min-width: ${MEDIA_LARGE}px) {
    margin: 4rem -5rem 0 -5rem;
    border-radius: 24px;
    padding: ${SPACING_10};
  }
`;

// const Container = styled.div`
//   max-width: 1280px;
//   border-radius: 0px;
//   margin: 4rem auto 0 auto;
// `;

export const SectionOne: React.FC<{
  tag: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrInSectionOneDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrInSectionOneDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrChOneSectionOneDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChOneSectionOneDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrChTwoSectionOneDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChTwoSectionOneDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrChThreeSectionOneDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChThreeSectionOneDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFourSectionOneDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChFourSectionOneDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFiveSectionOneDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChFiveSectionOneDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrSuSectionOneDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrSuSectionOneDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
}> = ({ tag, description }) => {
  return (
    // <Container>
    <Wrapper>
      <Overflow aria-label="Reading time">
        <Tag $color={NEUTRAL_WHITE} $backgroundColor={RED_700}>
          {tag?.toUpperCase()}
        </Tag>
      </Overflow>
      <Description>
        {description?.map((item, i) => (
          <P key={i}>
            {item?.paragraphs?.map((text, k) => (
              <Fragment key={k}>
                {text?.paragraphBold?.length && text?.paragraphBold[0] === 'bold' ? (
                  <b>{text?.paragraph}</b>
                ) : (
                  <>{text?.paragraph}</>
                )}
              </Fragment>
            ))}
          </P>
        ))}
      </Description>
    </Wrapper>
    // </Container>
  );
};
