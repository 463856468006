'use client';

import { MEDIA_LARGE, MEDIA_SMALL, SIZING_3, SIZING_4 } from '@mnd-frontend/ui';
import { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { P } from '../ui';

const H2 = styled.h2<{ $isGermanPage: boolean }>`
  font-weight: 700;
  font-size: ${SIZING_4};
  ${({ $isGermanPage }) =>
    $isGermanPage &&
    css`
      @media (max-width: ${MEDIA_SMALL}px) {
        font-size: ${SIZING_3};
      }
    `}
`;

const Article = styled.div`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
  max-height: fit-content;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
`;

const Section = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionTwoSummary: React.FC<{
  header: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrSuSectionTwoDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrSuSectionTwoDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
  locale?: string;
}> = ({ header, description, locale }) => {
  const isGermanPage = locale === 'de-de';

  return (
    <Section>
      <Container>
        <Article>
          <H2 $isGermanPage={isGermanPage}>{header}</H2>
          {description?.map((item, i) => (
            <P key={i}>
              {item?.paragraphs?.map((text, k) => (
                <Fragment key={k}>
                  {text?.paragraphBold?.length && text?.paragraphBold[0] === 'bold' ? (
                    <b>{text?.paragraph}</b>
                  ) : (
                    <>{text?.paragraph}</>
                  )}
                </Fragment>
              ))}
            </P>
          ))}
        </Article>
      </Container>
    </Section>
  );
};
