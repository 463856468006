'use client';

import { toBoolean } from '@mnd-frontend/ts';
import { MEDIA_LARGE, PURPLE_100, PURPLE_700, RED_100, RED_700 } from '@mnd-frontend/ui';
import { Fragment } from 'react';
import { styled } from 'styled-components';
import { SrOnly } from '../../../../../../../components/SrOnly';

const Small = styled.small`
  margin: auto 0;
  font-size: 1rem;
`;

const Span = styled.span<{ $color: string }>`
  font-weight: 900;
  font-size: 1.45rem;
  color: ${props => props.$color};
`;

const Stat = styled.div`
  display: flex;
  column-gap: 1rem;
`;

const Stats = styled.div`
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
`;

const Heading = styled.span`
  margin: 0;
  font-size: 1.15rem;
  font-weight: 700;
`;

const RightInner = styled.div`
  display: flex;
  row-gap: 2rem;
  flex-direction: column;
  @media (min-width: ${MEDIA_LARGE}px) {
    max-width: 90%;
    margin-left: auto;
  }
`;

const Right = styled.div`
  padding: 3rem;
  background-color: ${RED_100};
  @media (min-width: ${MEDIA_LARGE}px) {
    width: 103.5%;
    margin-left: -3.5%;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    clip-path: polygon(10% 0, 100% 0%, 100% 100%, 0 100%);
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    border-radius: 24px;
  }
`;

const LeftInner = styled.div`
  display: flex;
  row-gap: 2rem;
  flex-direction: column;
  @media (min-width: ${MEDIA_LARGE}px) {
    max-width: 90%;
  }
`;

const Left = styled.div`
  padding: 3rem;
  background-color: ${PURPLE_100};
  @media (min-width: ${MEDIA_LARGE}px) {
    width: 103.5%;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    clip-path: polygon(0% 0, 100% 0%, 90% 100%, 0 100%);
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    border-radius: 24px;
  }
`;

const Box = styled.figure`
  display: grid;
  margin: 0;
  @media (min-width: ${MEDIA_LARGE}px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    row-gap: 2rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const DualStat: React.FC<{
  blueHeader?: string | null;
  redHeader?: string | null;
  blueStats:
    | ({
        percentage: number | null;
        description: Array<{
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
  redStats:
    | ({
        percentage: number | null;
        description: Array<{
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
}> = ({ blueHeader, redHeader, blueStats, redStats }) => {
  return (
    <Box>
      <SrOnly as="figcaption">{blueHeader ? blueHeader : redHeader ? redHeader : ''}</SrOnly>
      <Left>
        <LeftInner>
          {!!blueHeader && <Heading>{blueHeader}</Heading>}
          <Stats>
            {blueStats?.filter(toBoolean).map((item, i) => (
              <Stat key={i}>
                <Span $color={PURPLE_700}>{item.percentage}%</Span>
                <Small>
                  {item.description
                    ?.filter(toBoolean)
                    .map((paragraph, k) => (
                      <Fragment key={k}>
                        {paragraph.paragraphBold?.length &&
                        paragraph.paragraphBold[0] === 'bold' ? (
                          <b>{paragraph.paragraph}</b>
                        ) : (
                          <>{paragraph.paragraph}</>
                        )}
                      </Fragment>
                    ))}
                </Small>
              </Stat>
            ))}
          </Stats>
        </LeftInner>
      </Left>
      <Right>
        <RightInner>
          {!!redHeader && <Heading>{redHeader}</Heading>}
          <Stats>
            {redStats?.filter(toBoolean).map((item, i) => (
              <Stat key={i}>
                <Span $color={RED_700}>{item.percentage}%</Span>
                <Small>
                  {item.description
                    ?.filter(toBoolean)
                    .map((paragraph, k) => (
                      <Fragment key={k}>
                        {paragraph.paragraphBold?.length &&
                        paragraph.paragraphBold[0] === 'bold' ? (
                          <b>{paragraph.paragraph}</b>
                        ) : (
                          <>{paragraph.paragraph}</>
                        )}
                      </Fragment>
                    ))}
                </Small>
              </Stat>
            ))}
          </Stats>
        </RightInner>
      </Right>
    </Box>
  );
};
