import { MEDIA_LARGE, RED_700 } from '@mnd-frontend/ui';
import styled from 'styled-components';

export const Ol = styled.ol`
  margin: 0;
  padding: 0;
  display: flex;
  row-gap: 3rem;
  list-style: none;
  counter-reset: item;
  flex-direction: column;
`;

export const OlLi = styled.li`
  display: flex;
  counter-increment: item;
  &:before {
    max-width: 30px;
    font-weight: 700;
    font-size: 2.5rem;
    color: ${RED_700};
    margin-top: -1.3rem;
    content: counter(item) '.';
  }
  @media (min-width: ${MEDIA_LARGE}px) {
    &:before {
      margin-right: 2rem;
    }
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    &:before {
      margin-right: 1rem;
    }
  }
`;
