import { SummaryFragment } from '../../../../../../../wp-generated/types';
import { Header, SummaryFooter } from '../navigation';
import { Chapter } from '../patterns';
import { SectionEightSummary } from './section-eight-summary';
import { SectionFiveSummary } from './section-five-summary';
import { SectionFourSummary } from './section-four-summary';
import { SectionNineSummary } from './section-nine-summary';
import { SectionSevenSummary } from './section-seven-summary';
import { SectionSixSummary } from './section-six-summary';
import { SectionThreeSummary } from './section-three-summary';
import { SectionTwoSummary } from './section-two-summary';

export const PrReportSummary = ({
  moduleData,
  locale,
}: {
  moduleData: SummaryFragment;
  locale?: string;
}) => {
  return (
    <>
      <Header
        navigation={moduleData.navigationItems}
        downloadableText={moduleData.downloadableText}
        downloadableUrl={moduleData.downloadableUrl}
      />
      <Chapter
        subheader={moduleData.prSuChapterSubheader}
        header={moduleData.prSuChapterHeader}
        tag={moduleData.prSuSectionOneTag}
        description={moduleData.prSuSectionOneDescription}
        locale={locale}
      />
      <SectionTwoSummary
        header={moduleData.prSuSectionTwoHeader ?? ''}
        description={moduleData.prSuSectionTwoDescription}
        locale={locale}
      />
      <SectionThreeSummary
        header={moduleData.prSuSectionThreeHeader ?? ''}
        description={moduleData.prSuSectionThreeDescription}
        quote={moduleData.prSuSectionThreeQuote ?? ''}
        authorImage={moduleData.prSuSectionThreeAuthorImage?.node}
        authorName={moduleData.prSuSectionThreeAuthorName ?? ''}
        authorDescription={moduleData.prSuSectionThreeAuthorDescription ?? ''}
      />
      <SectionFourSummary
        header={moduleData.prSuSectionFourHeader ?? ''}
        description={moduleData.prSuSectionFourDescription}
      />
      <SectionFiveSummary
        header={moduleData.prSuSectionFiveHeader ?? ''}
        description={moduleData.prSuSectionFiveDescription}
        quote={moduleData.prSuSectionFiveQuote ?? ''}
        authorImage={moduleData.prSuSectionFiveAuthorImage?.node}
        authorName={moduleData.prSuSectionFiveAuthorName ?? ''}
        authorDescription={moduleData.prSuSectionFiveAuthorDescription ?? ''}
      />
      <SectionSixSummary
        header={moduleData.prSuSectionSixHeader ?? ''}
        itemHeader={moduleData.prSuSectionSixItemHeader ?? ''}
        description={moduleData.prSuSectionSixDescription}
        list={moduleData.prSuSectionSixItems}
      />
      <SectionSevenSummary
        header={moduleData.prSuSectionSevenHeader ?? ''}
        description={moduleData.prSuSectionSevenDescription}
        quote={moduleData.prSuSectionSevenQuote ?? ''}
        authorImage={moduleData.prSuSectionSevenAuthorImage?.node}
        authorName={moduleData.prSuSectionSevenAuthorName ?? ''}
        authorDescription={moduleData.prSuSectionSevenAuthorDescription ?? ''}
      />
      <SectionEightSummary
        header={moduleData.prSuSectionEightHeader ?? ''}
        description={moduleData.prSuSectionEightDescription}
      />
      {moduleData.prSuSectionNineHeader && moduleData.prSuSectionNineDescription && (
        <SectionNineSummary
          header={moduleData.prSuSectionNineHeader}
          description={moduleData.prSuSectionNineDescription}
        />
      )}
      <SummaryFooter
        subheader={moduleData.prSuFooterSubheader}
        header={moduleData.prSuFooterHeader}
        cta={moduleData.prSuFooterCta}
      />
    </>
  );
};
