'use client';

import { useMediaQuery } from '@mnd-frontend/hooks';
import { Icons, MEDIA_SMALL, NEUTRAL_WHITE, RED_700, RED_800 } from '@mnd-frontend/ui';
import { styled } from 'styled-components';

const FooterComponent = styled.footer`
  height: 248px;
  display: flex;
  padding: 2rem;
  align-items: center;
  background-color: ${RED_700};
  &:hover {
    background: ${RED_800};
    i {
      transform: translateX(10%);
    }
  }
`;

/**
 * @description =(
 * @see https://github.com/vercel/next.js/discussions/64435#discussioncomment-11128521
 */
const StyledLink = styled('a')`
  color: ${NEUTRAL_WHITE};
  font-size: 1.5rem;
  line-height: 2;
  font-weight: 700;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  i {
    transition: transform 250ms;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChapterHeading = styled.span`
  font-size: 3.5rem;
  line-height: 1;
  position: relative;
  padding-right: 5rem;
  @media (max-width: ${MEDIA_SMALL}px) {
    font-size: 2rem;
  }
`;
const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  margin-left: 1rem;
`;

export const Footer: React.FC<{
  subheader: string | null;
  header: string | null;
  link: {
    __typename?: 'AcfLink';
    url: string | null;
  } | null;
}> = ({ subheader, header, link }) => {
  const isMobile = useMediaQuery(`@media (max-width: ${MEDIA_SMALL}px)`);

  return (
    <FooterComponent>
      <StyledLink href={link?.url ?? '#'}>
        <TextWrapper>
          <span>{subheader}</span>
          <ChapterHeading>
            <strong>{header}</strong>
            <IconWrapper>
              <Icons.ArrowRight $size={isMobile ? 'lg' : 'xl'} aria-hidden="true" />
            </IconWrapper>
          </ChapterHeading>
        </TextWrapper>
      </StyledLink>
    </FooterComponent>
  );
};
