'use client';

import { MEDIA_LARGE, RED_100, RED_700, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import { Fragment } from 'react';
import styled from 'styled-components';
import { Author } from '../patterns/author';
import { P, Tag } from '../ui';

const ExtendedP = styled(P)`
  margin-bottom: 0.5rem;
`;

const Quote = styled.span`
  margin-top: 0;
  font-size: 5rem;
  line-height: 0.75;
`;

const Blockquote = styled.blockquote`
  display: flex;
  margin: 4rem 0;
  column-gap: 1rem;
  font-weight: 600;
  color: ${RED_700};
  font-size: 1.25rem;
`;

const H3 = styled.h3`
  display: flex;
  opacity: 0.85;
  row-gap: 1rem;
  font-size: 1.45rem;
  flex-direction: column;
  margin: 0 0 1.5rem 0;
`;

const Container = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: auto 1fr;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
  }
`;

const SSection = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionFiveChapterTwo: React.FC<{
  tag: string | null;
  header: string | null;
  description: string | null;
  quote: string | null;
  list:
    | ({
        paragraphs: Array<{
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
  cardDescription:
    | ({
        paragraph: string | null;
        paragraphBold: Array<string | null> | null;
      } | null)[]
    | null;
  authorImage:
    | {
        altText: string | null;
        mediaItemUrl: string | null;
      }
    | undefined;
  authorVideo: string | null;
  authorVideoPlayText: string | null;
  authorName: string | null;
  authorDescription: string | null;
}> = ({
  tag,
  header,
  description,
  quote,
  list,
  cardDescription,
  authorImage,
  authorVideo,
  authorVideoPlayText,
  authorName,
  authorDescription,
}) => {
  return (
    <SSection>
      <Container>
        <Author
          authorVideo={authorVideo}
          authorName={authorName}
          authorDescription={authorDescription}
          imageUrl={authorImage?.mediaItemUrl}
          playText={authorVideoPlayText}
          description={cardDescription}
        />
        <div>
          <H3>
            <Tag $color={RED_700} $backgroundColor={RED_100}>
              {tag?.toUpperCase()}
            </Tag>
            {header}
          </H3>
          <P>
            <b>{description}</b>
          </P>
          <Blockquote>
            <Quote>“</Quote>
            <i>{quote}</i>
          </Blockquote>
          <>
            {list?.map((item, i) => (
              <ExtendedP key={i}>
                {item?.paragraphs?.map((text, k) => (
                  <Fragment key={k}>
                    {text?.paragraphBold?.length && text?.paragraphBold[0] === 'bold' ? (
                      <b>{text?.paragraph}</b>
                    ) : (
                      <>{text?.paragraph}</>
                    )}
                  </Fragment>
                ))}
              </ExtendedP>
            ))}
          </>
        </div>
      </Container>
    </SSection>
  );
};
