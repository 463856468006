'use client';

import {
  MEDIA_LARGE,
  MEDIA_SMALL,
  NEUTRAL_WHITE,
  RED_100,
  RED_500,
  RED_700,
  SIZING_4,
  SIZING_5,
  SPACING_12,
  SPACING_2,
  SPACING_4,
  SPACING_5,
  SPACING_6,
  SPACING_8,
} from '@mnd-frontend/ui';
import { Fragment, useContext } from 'react';
import styled, { css } from 'styled-components';
import { scrolledPassedHeroContext } from '../../context';
import { P, Tag } from '../ui';
import Share from './share';

const Span = styled.span`
  letter-spacing: -0.05ch;
  line-height: 0.9;
  font-weight: 900;
  color: ${RED_500};
  display: block;
  @media (min-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_5};
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_4};
  }
`;

const ChapterHeading = styled.h2<{ $isGermanPage: boolean }>`
  line-height: 1;
  max-width: 900px;
  font-weight: 900;
  letter-spacing: -0.05ch;
  color: ${RED_700};
  font-size: 3rem;
  @media (min-width: ${MEDIA_LARGE}px) {
    font-size: 5rem;
  }
  @media (max-width: ${MEDIA_SMALL}px) {
    ${({ $isGermanPage }) =>
      $isGermanPage &&
      css`
        font-size: 1.8rem;
        & > span {
          font-size: 1.4rem;
        }
      `}
  }
`;

const ChapterInfoWrapper = styled.div`
  background: ${NEUTRAL_WHITE};
  margin: ${SPACING_8} auto 0 auto;
  @media (min-width: ${MEDIA_LARGE}px) {
    margin: 10rem calc(50% - 50vw) 0 calc(50% - 50vw);
    width: 100vw;
  }
`;

const ChapterInfo = styled.div`
  position: relative;
  margin: 0 -2rem;
  max-width: calc(1280px + ${SPACING_2} * 2);
  border-radius: 24px;
  background-color: ${NEUTRAL_WHITE};
  padding: ${SPACING_5} ${SPACING_2} ${SPACING_5} ${SPACING_2};
  @media (min-width: ${MEDIA_LARGE}px) {
    margin: -1rem auto 0 auto;
    max-width: calc(1280px + ${SPACING_6} * 2);
    transform: translateY(-50px);
    padding: ${SPACING_6};
  }
`;

const ReadingTime = styled.aside`
  position: absolute;
  top: -1.5rem;
  display: flex;
  width: calc(100% - ${SPACING_4});
  @media screen and (min-width: ${MEDIA_LARGE}px) {
    width: calc(100% - ${SPACING_12});
  }
  justify-content: space-between;
  align-items: center;
  & > span {
    font-size: 1rem;
    @media (min-width: ${MEDIA_LARGE}px) {
      font-size: 1.2rem;
    }
  }
`;

const Container = styled.div<{ $space: boolean }>`
  margin: 0 auto;
  max-width: 1280px;
  padding: ${props =>
    props.$space ? 'calc(15rem + 86.5px) 2rem 0rem 2rem' : '15rem 2rem 0rem 2rem'};
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: ${props => (props.$space ? 'calc(20rem + 86.5px) 0 0 0' : '20rem 0 0 0')};
  }
`;

const Section = styled.section<{ $space: boolean }>`
  background-color: ${RED_100};
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 0 5rem;
  }
`;

export const Chapter: React.FC<{
  subheader: string | null;
  header: string | null;
  headerIsH2?: boolean;
  tag: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrInSectionOneDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrInSectionOneDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrChOneSectionOneDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChOneSectionOneDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrChTwoSectionOneDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChTwoSectionOneDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrChThreeSectionOneDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChThreeSectionOneDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFourSectionOneDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChFourSectionOneDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFiveSectionOneDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChFiveSectionOneDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrSuSectionOneDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrSuSectionOneDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
  locale?: string;
}> = ({ subheader, header, tag, description, headerIsH2 = false, locale }) => {
  const isGermanPage = locale === 'de-de';
  const { scrolledPassedHero } = useContext(scrolledPassedHeroContext);
  return (
    <Section id="pr-report-chapter-section" $space={scrolledPassedHero}>
      <Container $space={scrolledPassedHero}>
        <ChapterHeading as={headerIsH2 ? 'h2' : 'h1'} $isGermanPage={isGermanPage}>
          <Span>{subheader}</Span>
          {header?.toUpperCase()}
        </ChapterHeading>
        <ChapterInfoWrapper>
          <ChapterInfo>
            <ReadingTime aria-label="Reading time">
              <Tag $color={NEUTRAL_WHITE} $backgroundColor={RED_700}>
                {tag?.toUpperCase()}
              </Tag>
              <Share chapterTitle={header} />
            </ReadingTime>
            {description?.map((item, i) => (
              <P key={i}>
                {item?.paragraphs?.map((text, k) => (
                  <Fragment key={k}>
                    {text?.paragraphBold?.length && text?.paragraphBold[0] === 'bold' ? (
                      <b>{text?.paragraph}</b>
                    ) : (
                      <>{text?.paragraph}</>
                    )}
                  </Fragment>
                ))}
              </P>
            ))}
          </ChapterInfo>
        </ChapterInfoWrapper>
      </Container>
    </Section>
  );
};
