import { IntroFragment } from '../../../../../../../wp-generated/types';
import { ScrolledPassedHeroProvider } from '../../context';
import { Footer, Header } from '../navigation';
import { Chapter } from '../patterns';
import { SectionFourIntro } from './section-four-intro';
import { SectionThreeIntro } from './section-three-intro';
import { SectionTwoIntro } from './section-two-intro';

export const PrReportIntro = ({
  moduleData,
  hero,
  locale,
}: {
  moduleData: IntroFragment;
  hero?: {
    heroBottomText: string | null;
    heroTopText: string | null;
    heroDescription: string | null;
    heroHeader: string | null;
    heroImage: { node: { mediaItemUrl: string | null } } | null;
  } | null;
  locale?: string;
}) => {
  return (
    <ScrolledPassedHeroProvider>
      <Header
        bottomText={hero?.heroBottomText}
        topText={hero?.heroTopText}
        navigation={moduleData.navigationItems}
        downloadableText={moduleData.downloadableText}
        downloadableUrl={moduleData.downloadableUrl}
        header={hero?.heroHeader}
        description={hero?.heroDescription}
        image={hero?.heroImage?.node.mediaItemUrl}
      />
      <Chapter
        subheader={moduleData.prInChapterSubheader}
        header={moduleData.prInChapterHeader}
        headerIsH2
        tag={moduleData.prInSectionOneTag}
        description={moduleData.prInSectionOneDescription}
        locale={locale}
      />
      <SectionTwoIntro
        listPrefix={moduleData.prInSectionTwoListPrefix}
        header={moduleData.prInSectionTwoHeader}
        description={moduleData.prInSectionTwoDescription}
        statement={moduleData.prInSectionTwoStatement}
        subheader={moduleData.prInSectionTwoSubheader}
        statPercentage={moduleData.prInSectionTwoStatPercentage}
        statParagraphs={moduleData.prInSectionTwoStatParagraphs}
        list={moduleData.prInSectionTwoList}
      />
      <SectionThreeIntro
        tag={moduleData.prInSectionThreeTag}
        header={moduleData.prInSectionThreeHeader}
        list={moduleData.prInSectionThreeList}
      />
      <SectionFourIntro
        tag={moduleData.prInSectionFourTag}
        experts={moduleData.prInSectionFourExperts}
      />
      <Footer
        subheader={moduleData.prInFooterSubheader}
        header={moduleData.prInFooterHeader}
        link={moduleData.link}
      />
    </ScrolledPassedHeroProvider>
  );
};
