'use client';

import { GREY_1000, NEUTRAL_WHITE, RED_100, RED_700 } from '@mnd-frontend/ui';
import { Fragment } from 'react';
import { styled } from 'styled-components';

const CardText = styled.figcaption`
  line-height: 1.5;
  font-size: 0.875rem;
  font-weight: 500;
`;
const StatHeader = styled.span<{ $inverted: boolean }>`
  line-height: 1;
  font-weight: 900;
  color: ${props => (props.$inverted ? NEUTRAL_WHITE : RED_700)};
  letter-spacing: -0.05ch;
  font-size: 8rem;
  display: block;
`;
const Card = styled.figure<{ $inverted: boolean }>`
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 24px;
  padding: 2.5rem 2.5rem;
  row-gap: 1rem;
  flex-direction: column;
  color: ${props => (props.$inverted ? NEUTRAL_WHITE : GREY_1000)};
  justify-content: center;
  background-color: ${props => (props.$inverted ? RED_700 : RED_100)};
  width: 400px;
  max-width: 100%;
  margin: 0;
`;

export const StatsCard = ({
  number,
  paragraphs,
  inverted = false,
}: {
  number: number | null;
  paragraphs:
    | ({
        paragraph: string | null;
        paragraphBold: Array<string | null> | null;
      } | null)[]
    | null;
  inverted?: boolean;
}) => {
  return (
    <Card $inverted={inverted}>
      <CardText>
        <StatHeader $inverted={inverted}>{number}%</StatHeader>
        {paragraphs?.map((item, i) => (
          <Fragment key={i}>
            {item?.paragraphBold?.length && item?.paragraphBold[0] === 'bold' ? (
              <b>{item?.paragraph}</b>
            ) : (
              <>{item?.paragraph}</>
            )}
          </Fragment>
        ))}
      </CardText>
    </Card>
  );
};
