'use client';

import { createContext, useState, type ReactNode } from 'react';

interface ScrolledPassedHero {
  scrolledPassedHero: boolean;
  setScrolledPassedHero: React.Dispatch<React.SetStateAction<boolean>>;
}

export const scrolledPassedHeroContext = createContext({} as ScrolledPassedHero);

export const ScrolledPassedHeroProvider = ({ children }: { children: ReactNode }) => {
  const [scrolledPassedHero, setScrolledPassedHero] = useState(false);

  return (
    <scrolledPassedHeroContext.Provider value={{ scrolledPassedHero, setScrolledPassedHero }}>
      {children}
    </scrolledPassedHeroContext.Provider>
  );
};
