'use client';

import { InfoModal, MEDIA_LARGE, RED_100, RED_700, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Author } from '../patterns/author';
import { P, Tag } from '../ui';

const Description = styled.div`
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
`;

const Quote = styled.span`
  margin-top: 0;
  font-size: 5rem;
  line-height: 0.75;
`;

const Blockquote = styled.blockquote`
  display: flex;
  margin: 4rem 0;
  column-gap: 1rem;
  font-weight: 600;
  color: ${RED_700};
  font-size: 1.25rem;
`;

const H2 = styled.h2`
  display: flex;
  opacity: 0.85;
  row-gap: 1rem;
  font-size: 1.45rem;
  flex-direction: column;
  margin: 0 0 1.5rem 0;
`;

const Container = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: auto 1fr;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    display: flex;
    flex-direction: column-reverse;
  }
`;

const Section = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionSixChapterFive: React.FC<{
  authorImage:
    | {
        __typename?: 'MediaItem';
        altText: string | null;
        mediaItemUrl: string | null;
      }
    | undefined;
  authorName: string | null;
  authorDescription: string | null;
  tag: string | null;
  header: string | null;
  description: string | null;
  quote: string | null;
  list:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFiveSectionSixParagraphs';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChFiveSectionSixParagraphsParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
}> = ({ authorImage, authorName, authorDescription, tag, header, description, quote, list }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <InfoModal
        pillText="Work in progress"
        size="lg"
        description="Info modal using iframe"
        onClose={() => setOpen(false)}
        open={open}
        media={<iframe src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe>}
        headline="Example:"
      />
      <Section>
        <Container>
          <Author
            imageUrl={authorImage?.mediaItemUrl ?? ''}
            authorName={authorName}
            authorDescription={authorDescription}
          />
          <div>
            <H2>
              <Tag $color={RED_700} $backgroundColor={RED_100}>
                {tag?.toUpperCase()}
              </Tag>
              {header}
            </H2>
            <P>{description}</P>
            {quote && (
              <Blockquote>
                <Quote>“</Quote>
                <i>{quote}</i>
              </Blockquote>
            )}
            <Description>
              {list?.map((item, i) => (
                <P key={i}>
                  {item?.paragraphs?.map((text, k) => (
                    <Fragment key={k}>
                      {text?.paragraphBold?.length && text?.paragraphBold[0] === 'bold' ? (
                        <b>{text?.paragraph}</b>
                      ) : (
                        <>{text?.paragraph}</>
                      )}
                    </Fragment>
                  ))}
                </P>
              ))}
            </Description>
          </div>
        </Container>
      </Section>
    </>
  );
};
