'use client';

import { toBoolean } from '@mnd-frontend/ts';
import { PURPLE_100, PURPLE_700, RED_100, RED_700 } from '@mnd-frontend/ui';
import { Fragment } from 'react';
import { styled } from 'styled-components';

const Small = styled.small`
  margin: auto 0;
  font-size: 1rem;
`;

const Span = styled.span<{ $color: string }>`
  font-weight: 900;
  font-size: 1.45rem;
  color: ${props => props.$color};
`;

const Figcaption = styled.figcaption`
  margin: 0 0 1rem 0;
  font-size: 1.15rem;
  font-weight: 700;
`;

const Row = styled.div`
  display: flex;
  column-gap: 1rem;
`;

const Container = styled.figure<{ $color: string }>`
  padding: 2.5rem;
  display: flex;
  row-gap: 1.5rem;
  border-radius: 24px;
  height: fit-content;
  flex-direction: column;
  background-color: ${props => props.$color};
  width: 400px;
  max-width: 100%;
  margin: 0 auto;
`;

export const Stat: React.FC<{
  color: 'red' | 'purple';
  stats:
    | ({
        percentage: number | null;
        description: Array<{
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
  header?: string | null;
}> = ({ color, stats, header }) => {
  return (
    <Container $color={color === 'red' ? RED_100 : PURPLE_100}>
      {header ? <Figcaption>{header}</Figcaption> : null}
      {stats?.filter(toBoolean).map((item, i) => (
        <Row key={i}>
          <Span $color={color === 'red' ? RED_700 : PURPLE_700}>{item.percentage}%</Span>
          <Small>
            {item.description?.filter(toBoolean).map((paragraph, k) => (
              <Fragment key={k}>
                {paragraph.paragraphBold?.length && paragraph.paragraphBold[0] === 'bold' ? (
                  <b>
                    <i>{paragraph.paragraph}</i>
                  </b>
                ) : (
                  <i>{paragraph.paragraph}</i>
                )}
              </Fragment>
            ))}
          </Small>
        </Row>
      ))}
    </Container>
  );
};
