'use client';

import { toBoolean } from '@mnd-frontend/ts';
import {
  MEDIA_LARGE,
  NEUTRAL_WHITE,
  RED_100,
  RED_700,
  SPACING_3,
  withLineClamp,
} from '@mnd-frontend/ui';
import { Fragment } from 'react';
import { styled } from 'styled-components';

const Percentage = styled.span`
  font-weight: 600;
  margin-left: auto;
  font-size: 1.125rem;
  color: ${NEUTRAL_WHITE};
  margin-right: 1rem;
  padding-left: 0.5rem;
`;

const OutsidePercentage = styled.div`
  padding-left: 1rem;
  font-size: 1.125rem;
  color: ${RED_700};
  font-weight: 600;
`;

const Bar = styled.div`
  display: flex;
  background-color: ${RED_700};
  border-top-right-radius: 4px;
  align-items: center;
  height: 100%;
  border-bottom-right-radius: 4px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  &:before {
    content: '';
    width: 2px;
    margin-top: -0.5rem;
    height: calc(100% + 1rem);
    background-color: black;
  }
`;

const Text = styled.div`
  font-size: 1rem;
  text-align: right;
  ${withLineClamp(3)}
`;

const Row = styled.div`
  width: 100%;
  gap: ${SPACING_3};
`;

const Card = styled.figure`
  display: flex;
  padding: 1rem;
  row-gap: 2rem;
  height: fit-content;
  border-radius: 24px;
  margin: 0 auto;
  flex-direction: column;
  background-color: ${RED_100};
  width: 400px;
  max-width: 100%;
  @media (min-width: ${MEDIA_LARGE}px) {
    position: sticky;
    top: calc(45% - 86.5px);
  }
`;

const ChartHeader = styled.figcaption`
  margin: 0;
  font-size: 1.125rem;
  padding: 1rem 1rem 0 1rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
`;

const ChartGrid = styled.div`
  grid-auto-rows: 1fr;
  align-items: center;
  gap: 1rem;
  grid-template-columns: 2fr 1fr;
  display: grid;
`;

interface ChartProps {
  header: string | null;
  chart:
    | ({ description: string | null; width: string | null; percentage: number | null } | null)[]
    | null;
}

export const Chart: React.FC<ChartProps> = ({ header, chart }) => {
  const items =
    chart
      ?.filter(toBoolean)
      .map(item => ({ percentage: item.percentage ?? 0, description: item.description })) ?? [];

  const highestPercentage = items.reduce((max, curr) => Math.max(max, curr.percentage), 1);

  return (
    <Card>
      <ChartHeader>{header}</ChartHeader>
      <ChartGrid>
        {items.map((item, index) => {
          const barWidthPercentage = Math.ceil((item.percentage / highestPercentage) * 100);
          const showPercentageInside = barWidthPercentage > 35;
          return (
            <Fragment key={index}>
              <Text>{item.description}</Text>
              <Column>
                <Bar style={{ width: `${barWidthPercentage}%` }}>
                  {showPercentageInside ? <Percentage>{item.percentage}%</Percentage> : null}
                </Bar>
                {showPercentageInside ? null : (
                  <OutsidePercentage>{item.percentage}%</OutsidePercentage>
                )}
              </Column>
            </Fragment>
          );
        })}
      </ChartGrid>
    </Card>
  );
};
