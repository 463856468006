'use client';

import {
  MEDIA_LARGE,
  RED_100,
  RED_600,
  RED_700,
  SIZING_4,
  SIZING_5,
  SPACING_12,
  SPACING_8,
} from '@mnd-frontend/ui';
import { styled } from 'styled-components';
import { StatsCard } from '../patterns/stats-card';
import { Ol, OlLi, P } from '../ui';

const SectionHeader = styled.h3`
  line-height: 1.15;
  margin: 1rem 0;
  font-size: ${SIZING_4};
  color: ${RED_700};
  @media (min-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_5};
  }
`;

const DealingWith = styled.strong`
  color: ${RED_700};
  font-weight: inherit;
`;

const Wrapper = styled.div`
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
`;

const Span = styled.span`
  line-height: 0.9;
  font-weight: 700;
  color: ${RED_600};
  display: block;
  @media (min-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_5};
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_4};
  }
`;

const DealingWithText = styled.span`
  font-weight: 600;
  font-size: 1.15rem;
`;

const Container = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  gap: ${SPACING_8};
  grid-template-columns: repeat(1, minmax(0, 1fr));
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const Section = styled.section`
  background-color: ${RED_100};
  padding: 5rem 2rem;
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
`;

const StatsCardWrapper = styled.div`
  @media (min-width: ${MEDIA_LARGE}px) {
    margin-top: -12rem;
  }
`;

export const SectionTwoIntro: React.FC<{
  listPrefix: string | null;
  header: string | null;
  description: string | null;
  statement: string | null;
  subheader: string | null;
  statPercentage: number | null;
  statParagraphs:
    | ({
        __typename?: 'ModulesFlexibleModulesPrInSectionTwoStatParagraphs';
        paragraph: string | null;
        paragraphBold: Array<string | null> | null;
      } | null)[]
    | null;
  list:
    | ({
        __typename?: 'ModulesFlexibleModulesPrInSectionTwoList';
        header: string | null;
        description: string | null;
      } | null)[]
    | null;
}> = ({
  listPrefix,
  header,
  description,
  statement,
  subheader,
  statPercentage,
  statParagraphs,
  list,
}) => {
  return (
    <Section>
      <Container>
        <div>
          <SectionHeader>
            <Span>{subheader}</Span>
            {header}
          </SectionHeader>
          <P>{description}</P>
          <P>
            <strong>{statement}</strong>
          </P>
        </div>
        <Ol>
          {list?.map((item, index) => (
            <OlLi key={index}>
              <Wrapper>
                <DealingWithText>
                  {listPrefix} <DealingWith>{item?.header}</DealingWith>
                </DealingWithText>
                <P>{item?.description}</P>
              </Wrapper>
            </OlLi>
          ))}
        </Ol>
        <StatsCardWrapper>
          <StatsCard number={statPercentage} paragraphs={statParagraphs} inverted />
        </StatsCardWrapper>
      </Container>
    </Section>
  );
};
