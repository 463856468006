import { MEDIA_LARGE, SIZING_5 } from '@mnd-frontend/ui';
import styled from 'styled-components';

export const H2 = styled.h2`
  line-height: 1.15;
  font-size: ${SIZING_5};

  @media (max-width: ${MEDIA_LARGE}px) {
    font-size: 1.45rem;
  }
`;
