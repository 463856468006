'use client';

import { MEDIA_LARGE, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import { Fragment } from 'react';
import { styled } from 'styled-components';
import { H2, P } from '../ui';
import { Chart } from '../ui/chart';

const Wrapper = styled.div`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
  max-height: fit-content;
`;

const Container = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: 1fr auto;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    display: flex;
    flex-direction: column;
  }
`;

const Section = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionTwoChapterTwo: React.FC<{
  header: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChTwoSectionTwoDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChTwoSectionTwoDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
  chartHeader: string | null;
  chart:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChTwoSectionTwoChart';
        description: string | null;
        width: string | null;
        percentage: number | null;
      } | null)[]
    | null;
}> = ({ header, description, chartHeader, chart }) => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <H2>{header}</H2>
          {description?.map((item, i) => (
            <P key={i}>
              {item?.paragraphs?.map((text, k) => (
                <Fragment key={k}>
                  {text?.paragraphBold?.length && text?.paragraphBold[0] === 'bold' ? (
                    <b>{text?.paragraph}</b>
                  ) : (
                    <>{text?.paragraph}</>
                  )}
                </Fragment>
              ))}
            </P>
          ))}
        </Wrapper>
        <Chart header={chartHeader} chart={chart} />
      </Container>
    </Section>
  );
};
