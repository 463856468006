'use client';

import { MEDIA_LARGE, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import { Fragment } from 'react';
import styled from 'styled-components';
import { Author } from '../patterns/author';
import { P } from '../ui';

const Description = styled.div`
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
`;

const H2 = styled.h2`
  margin: 0;
  opacity: 0.85;
  font-size: 1.45rem;
`;

const Article = styled.div`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
`;

const Container = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: 1fr auto;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    display: flex;
    flex-direction: column;
  }
`;

const Section = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionSevenChapterFive: React.FC<{
  authorImage:
    | {
        __typename?: 'MediaItem';
        altText: string | null;
        mediaItemUrl: string | null;
      }
    | undefined;
  authorName: string | null;
  authorVideo: string | null;
  authorVideoPlayText: string | null;
  authorDescription: string | null;
  cardDescription:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFiveSectionSevenCardDescription';
        paragraph: string | null;
        paragraphBold: Array<string | null> | null;
      } | null)[]
    | null;
  header: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFiveSectionSevenDescription';
        descriptionParagraph: string | null;
      } | null)[]
    | null;
  list:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFiveSectionSevenParagraphs';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChFiveSectionSevenParagraphsParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
}> = ({
  authorVideo,
  authorVideoPlayText,
  authorImage,
  authorName,
  authorDescription,
  cardDescription,
  header,
  description,
  list,
}) => {
  return (
    <Section>
      <Container>
        <Article>
          <H2>{header}</H2>
          {description?.map((item, index) => <P key={index}>{item?.descriptionParagraph}</P>)}
          <Description>
            {list?.map((item, i) => (
              <P key={i}>
                {item?.paragraphs?.map((text, k) => (
                  <Fragment key={k}>
                    {text?.paragraphBold?.length && text?.paragraphBold[0] === 'bold' ? (
                      <b>{text?.paragraph}</b>
                    ) : (
                      <>{text?.paragraph}</>
                    )}
                  </Fragment>
                ))}
              </P>
            ))}
          </Description>
        </Article>
        <Author
          authorVideo={authorVideo}
          authorName={authorName}
          authorDescription={authorDescription}
          imageUrl={authorImage?.mediaItemUrl}
          playText={authorVideoPlayText}
          description={cardDescription}
        />
      </Container>
    </Section>
  );
};
