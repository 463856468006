'use client';

import { MEDIA_LARGE, RED_100, RED_700, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import styled from 'styled-components';
import { Author } from '../patterns/author';
import { P, Tag } from '../ui';

const Quote = styled.span`
  margin-top: 0;
  font-size: 5rem;
  line-height: 0.75;
`;

const Blockquote = styled.blockquote`
  display: flex;
  column-gap: 1rem;
  font-weight: 600;
  color: ${RED_700};
  font-size: 1.25rem;
  margin: 4rem 0 0 0;
`;

const H2 = styled.h2`
  display: flex;
  opacity: 0.85;
  row-gap: 1rem;
  font-size: 1.45rem;
  flex-direction: column;
  margin: 0 0 1.5rem 0;
`;

const Container = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: 1fr auto;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    display: flex;
    flex-direction: column;
  }
`;

const Section = styled.section`
  background-color: ${RED_100};
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionSevenChapterFour: React.FC<{
  tag: string | null;
  header: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFourSectionSevenDescription';
        descriptionParagraph: string | null;
      } | null)[]
    | null;
  quote: string | null;
  authorImage:
    | {
        __typename?: 'MediaItem';
        altText: string | null;
        mediaItemUrl: string | null;
      }
    | undefined;
  authorName: string | null;
  authorDescription: string | null;
}> = ({ tag, header, description, quote, authorImage, authorName, authorDescription }) => {
  return (
    <Section>
      <Container>
        <div>
          <H2>
            <Tag $color={RED_100} $backgroundColor={RED_700}>
              {tag?.toUpperCase()}
            </Tag>
            {header}
          </H2>
          {description?.map((item, index) => <P key={index}>{item?.descriptionParagraph}</P>)}
          <Blockquote>
            <Quote>“</Quote>
            <i>{quote}</i>
          </Blockquote>
        </div>
        <Author
          imageUrl={authorImage?.mediaItemUrl ?? ''}
          authorName={authorName}
          authorDescription={authorDescription}
        />
      </Container>
    </Section>
  );
};
