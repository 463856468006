'use client';

import { useMediaQuery } from '@mnd-frontend/hooks';
import { Icons, MEDIA_LARGE, RED_600, RED_700, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import { Fragment } from 'react';
import { styled } from 'styled-components';

const P = styled.p`
  margin-top: 0.15rem;
  font-size: 1.15rem;
  max-width: 800px;
`;

const LI = styled.li`
  display: flex;
  column-gap: 1.5rem;
  @media (max-width: ${MEDIA_LARGE}px) {
    column-gap: 0.5rem;
  }
`;
const IconWrapper = styled.div`
  transform: translateY(-11px);
  @media (max-width: ${MEDIA_LARGE}px) {
    transform: translateY(-4px);
  }
`;

const UL = styled.ul`
  gap: 2rem;
  padding: 0;
  display: flex;
  list-style: none;
  flex-direction: column;
`;

const Span = styled.span`
  color: ${RED_600};
  font-size: 1.75rem;
`;

const H2 = styled.h2`
  line-height: 1;
  color: ${RED_700};
  font-size: 2.75rem;
`;

const Container = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Section = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionNineChapterFour: React.FC<{
  subheader: string | null;
  header: string | null;
  list:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFourSectionNineParagraphs';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChFourSectionNineParagraphsParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
}> = ({ subheader, header, list }) => {
  const isMobile = useMediaQuery(`@media (max-width: ${MEDIA_LARGE}px)`);
  return (
    <Section>
      <Container>
        <H2>
          <Span>{subheader}</Span> <br /> {header}
        </H2>
        <UL>
          {list?.map((item, i) => (
            <LI key={i}>
              <IconWrapper>
                <Icons.Check $size={isMobile ? 'sm' : 'md'} $color="RED_700" />
              </IconWrapper>
              <P>
                {item?.paragraphs?.map((text, k) => (
                  <Fragment key={k}>
                    {text?.paragraphBold?.length && text?.paragraphBold[0] === 'bold' ? (
                      <b>{text?.paragraph}</b>
                    ) : (
                      <>{text?.paragraph}</>
                    )}
                  </Fragment>
                ))}
              </P>
            </LI>
          ))}
        </UL>
      </Container>
    </Section>
  );
};
