'use client';

import { toBoolean } from '@mnd-frontend/ts';
import { MEDIA_LARGE, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import { styled } from 'styled-components';
import { StatsCard } from '../patterns/stats-card';
import { H2, P, StatCards } from '../ui';
import { Chart } from '../ui/chart';

const Wrapper = styled.div`
  display: flex;
  row-gap: 4rem;
  flex-direction: column;
`;

const Article = styled.div`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
  max-height: fit-content;
  @media (min-width: ${MEDIA_LARGE}px) {
    position: sticky;
    top: calc(45% - 86.5px);
  }
`;

const Container = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: 1fr auto;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    display: flex;
    flex-direction: column;
  }
`;

const Section = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionFourChapterFive: React.FC<{
  header: string | null;
  description: string | null;
  stats:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFiveSectionFourStats';
        percentage: number | null;
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChFiveSectionFourStatsParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
  chart:
    | ({
        description: string | null;
        width: string | null;
        percentage: number | null;
      } | null)[]
    | null;
  chartHeader: string | null;
}> = ({ header, description, stats, chart, chartHeader }) => {
  return (
    <Section>
      <Container>
        <Article>
          <H2>{header}</H2>
          <P>{description}</P>
        </Article>
        <Wrapper>
          <StatCards>
            {stats
              ?.filter(toBoolean)
              .map((item, i) => (
                <StatsCard key={i} number={item.percentage} paragraphs={item.paragraphs} />
              ))}
          </StatCards>
          <Chart chart={chart} header={chartHeader} />
        </Wrapper>
      </Container>
    </Section>
  );
};
