'use client';

import { GREY_1000, MEDIA_LARGE, MEDIA_SMALL, NEUTRAL_WHITE, RED_700 } from '@mnd-frontend/ui';
import { usePathname } from 'next/navigation';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Video } from '../../../../../../../components/modules/Video';
import { P } from '../ui';

const AuthorDescription = styled.div`
  line-height: 1.2;
`;

const AuthorName = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5;
`;
const PlayButton = styled.div`
  border-radius: 0.5rem;
  flex-shrink: 0;
  overflow: hidden;
  padding: 0;
  display: flex;
  height: 3rem;
  background-color: ${GREY_1000};
  align-items: stretch;
`;

const Play = styled.div`
  padding: 1.5rem 1rem;
  background-color: ${RED_700};
  display: flex;
  align-items: center;

  > div {
    height: 21px;
    width: 21px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const PlaybuttonText = styled.div`
  padding: 1rem;
  font-weight: 700;
  font-size: 1rem;
  color: ${NEUTRAL_WHITE};
  white-space: nowrap;
  line-height: 1;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  @media (max-width: ${MEDIA_SMALL}px) {
    align-items: start;
    flex-direction: column;
    justify-content: start;
  }
`;

const Card = styled.div`
  overflow: hidden;
  position: relative;
  height: fit-content;
  border-radius: 24px;
  aspect-ratio: 3 / 4;
  color: ${NEUTRAL_WHITE};
  background-color: black;
`;

const Wrapper = styled.div`
  display: flex;
  width: 400px;
  row-gap: 1.5rem;
  margin: 0 auto;
  overflow: hidden;
  max-width: 100%;
  flex-direction: column;
  @media (min-width: ${MEDIA_LARGE}px) {
    position: sticky;
    top: calc(30% - 86.5px);
  }
`;
const VideoPlaceholder = styled.button<{ $imageUrl: string }>`
  cursor: pointer;
  font-family: inherit;
  aspect-ratio: 3 / 4;
  width: 100%;
  padding: 2.5rem;
  border-radius: 1.5rem;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  line-height: 0;
  background:
    linear-gradient(180deg, rgba(0, 0, 0, 0) 49.77%, rgba(0, 0, 0, 0.5) 100%),
    url(${props => props.$imageUrl}) lightgray 50% / cover no-repeat;
  color: ${NEUTRAL_WHITE};
  text-align: left;
  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    width: 100%;
  }
`;

const pathnameLocaleToSubtitles: Record<string, string> = {
  'de-de': 'de',
};

export const PrReportVideo = ({
  embedCode,
  author,
  authorDescription,
  imageUrl,
  playText,
  description,
}: {
  embedCode: string;
  author?: string | null;
  imageUrl?: string | null;
  authorDescription?: string | null;
  playText?: string | null;
  description?:
    | ({
        paragraph: string | null;
        paragraphBold: Array<string | null> | null;
      } | null)[]
    | null;
}) => {
  const { t } = useTranslation();
  const pathname = usePathname();
  const subtitleParam = pathnameLocaleToSubtitles[pathname.split('/')[1]];
  embedCode = subtitleParam
    ? embedCode.replace('?', `?cc_load_policy=1&cc_lang_pref=${subtitleParam}&`)
    : embedCode;

  return (
    <Wrapper>
      <Card>
        <Video
          embedCode={embedCode}
          title={author ?? ''}
          portrait
          renderPlaceholder={playVideo => (
            <VideoPlaceholder
              $imageUrl={imageUrl ?? ''}
              type="button"
              onClick={playVideo}
              aria-label={`${t('marketing_play_video')}, ${author}, ${authorDescription}`}
            >
              <Info>
                <div>
                  <AuthorName>{author}</AuthorName>
                  <AuthorDescription>{authorDescription}</AuthorDescription>
                </div>
                <PlayButton>
                  <Play>
                    <div>
                      <svg
                        width="16"
                        height="19"
                        viewBox="0 0 16 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16 9.24316L0.249999 18.3364L0.25 0.149897L16 9.24316Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </Play>
                  <PlaybuttonText>{playText ?? t('marketing_play_video')}</PlaybuttonText>
                </PlayButton>
              </Info>
            </VideoPlaceholder>
          )}
        />
      </Card>
      {description ? (
        <P>
          {description.map((item, index) => (
            <Fragment key={index}>
              {item?.paragraphBold?.length && item?.paragraphBold[0] === 'bold' ? (
                <b>{item?.paragraph}</b>
              ) : (
                <>{item?.paragraph}</>
              )}
            </Fragment>
          ))}
        </P>
      ) : null}
    </Wrapper>
  );
};
