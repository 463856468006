import { SPACING_1, SPACING_2 } from '@mnd-frontend/ui';
import styled from 'styled-components';

export const Tag = styled.span<{ $color: string; $backgroundColor: string }>`
  margin: 0;
  display: block;
  font-weight: 700;
  font-size: 1.2rem;
  border-radius: 8px;
  width: fit-content;
  color: ${props => props.$color};
  background-color: ${props => props.$backgroundColor};
  padding: ${SPACING_1} ${SPACING_2};
`;
