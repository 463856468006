'use client';

import {
  Icons,
  MEDIA_LARGE,
  RED_100,
  RED_600,
  RED_700,
  SPACING_12,
  SPACING_8,
} from '@mnd-frontend/ui';
import { styled } from 'styled-components';
import { P } from '../ui';

const H3 = styled.h3`
  margin: 0;
  font-size: 1.15rem;
`;

const Wrapper = styled.div`
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
`;

const LI = styled.li`
  display: flex;
  column-gap: 1.5rem;
`;

const UL = styled.ul`
  gap: 2rem;
  padding: 0;
  display: flex;
  list-style: none;
  flex-direction: column;
`;

const Span = styled.span`
  row-gap: 1rem;
  color: ${RED_600};
  font-size: 1.75rem;
`;

const H2 = styled.h2`
  line-height: 1;
  color: ${RED_700};
  font-size: 2.75rem;
`;

const Container = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Section = styled.section`
  background-color: ${RED_100};
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

const IconWrapper = styled.div`
  transform: translateY(-12px);
`;

export const Takeaways: React.FC<{
  subheader: string | null;
  title: string | null;
  list:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChOneTakeawayItems';
        header: string | null;
        description: string | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrChTwoTakeawayItems';
        header: string | null;
        description: string | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrChThreeTakeawayItems';
        header: string | null;
        description: string | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFourTakeawayItems';
        header: string | null;
        description: string | null;
      } | null)[]
    | null
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFiveTakeawayItems';
        header: string | null;
        description: string | null;
      } | null)[]
    | null;
}> = ({ subheader, title, list }) => {
  return (
    <Section>
      <Container>
        <H2>
          <Span>{subheader}</Span> <br /> {title}
        </H2>
        <UL>
          {list?.map((item, index) => (
            <LI key={index}>
              <IconWrapper>
                <Icons.ArrowRight $size="md" $color="RED_700" />
              </IconWrapper>
              <Wrapper>
                <H3>{item?.header}</H3>
                <P>{item?.description}</P>
              </Wrapper>
            </LI>
          ))}
        </UL>
      </Container>
    </Section>
  );
};
