'use client';

import { useMediaQuery } from '@mnd-frontend/hooks';
import {
  Button,
  GREY_300,
  Icons,
  MEDIA_LARGE,
  MEDIA_SMALL,
  NEUTRAL_WHITE,
  RED_100,
  RED_700,
  SIZING_2,
  SIZING_3,
  SIZING_4,
  SPACING_3,
  Text,
} from '@mnd-frontend/ui';
import { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { Tag } from '../ui';

const P = styled.p`
  opacity: 0.85;
  line-height: 1;
  font-size: 1.1rem;
`;

const ExpertName = styled.p`
  font-size: 1.125rem;
  font-weight: 700;
  margin: 1rem 0 0.25rem 0;
`;

const Section = styled.section`
  padding: 6rem 5rem 18rem 5rem;
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 6rem 5rem 14rem 5rem;
  }
  @media (max-width: ${MEDIA_SMALL}px) {
    padding: 5rem 2rem 14rem 2rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
  row-gap: ${SPACING_3};
  flex-direction: column;
`;

const Ul = styled.ul`
  gap: 2rem;
  margin: 0;
  padding: 0;
  display: grid;
  list-style: none;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @media (min-width: ${MEDIA_LARGE}px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_SMALL}px) {
    display: flex;
    flex-direction: column;
  }
`;

const Figure = styled.figure`
  margin: 0;
  padding: 0;
`;

const ImgWrapper = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 24px;
  aspect-ratio: 1 / 1;
  i {
    top: 50%;
    left: 50%;
    opacity: 0;
    z-index: 2;
    position: absolute;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
  }
  img {
    max-width: 100%;
  }
`;

const ExpandButton = styled.button`
  all: unset;
  cursor: pointer;

  &:hover ${ImgWrapper}:after {
    opacity: 1;
  }
  &:hover i {
    opacity: 1;
  }
`;

const CornerButtonWrapper = styled.div`
  position: absolute;
  top: 1.5rem;
  opacity: 0.7;
  right: 1.5rem;
`;

const Expert = styled.article<{ $show: boolean }>`
  top: 68px;
  gap: 1rem;
  z-index: 2;
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
  position: absolute;
  background: ${NEUTRAL_WHITE};
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-left: -1px;
  width: calc(100% + 2px);
  height: 100%;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: 2rem;
    display: grid;
    grid-template-columns: auto 1fr;
  }
`;

const ExpertImgWrapper = styled.div`
  border-radius: 24px;
  aspect-ratio: 1 / 1;
  max-height: 480px;
  background-color: ${GREY_300};
  overflow: hidden;
  @media (min-width: ${MEDIA_LARGE}px) {
    justify-self: end;
  }
  img {
    max-width: 100%;
    height: auto;
  }
`;

const ExpertDetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const ExpertHeader = styled.h3`
  font-size: ${SIZING_3};
  font-weight: 700;
  line-height: 1;
  margin: 0;
  @media (min-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_4};
  }
`;

const ExpertWorkTitle = styled.p`
  font-size: ${SIZING_2};
  font-weight: 400;
  line-height: 1;
  @media (min-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_3};
  }
`;

const ExpertDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 0;
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 2rem 0;
    width: 90%;
  }
`;

export const SectionFourIntro: React.FC<{
  tag: string | null;
  experts:
    | ({
        __typename?: 'ModulesFlexibleModulesPrInSectionFourExperts';
        aspectRatio: number | null;
        profession: string | null;
        description: string | null;
        marginTop: string | null;
        name: string | null;
        scale: number | null;
        image: {
          __typename?: 'AcfMediaItemConnectionEdge';
          node: {
            __typename?: 'MediaItem';
            altText: string | null;
            mediaItemUrl: string | null;
          };
        } | null;
      } | null)[]
    | null;
}> = ({ tag, experts }) => {
  const [showExpert, setShowExpert] = useState<number | null>(null);
  const isMobile = useMediaQuery(`@media (max-width: ${MEDIA_LARGE}px)`);
  const expertCardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showExpert) {
      if (expertCardRef.current) {
        const el = expertCardRef.current;
        const top = el.getBoundingClientRect().top + window.scrollY - 168;
        window.scrollTo({ top, behavior: 'instant' });
      }
    }
  }, [showExpert]);

  return (
    <Section>
      <Wrapper>
        <Tag as="h3" $color={RED_700} $backgroundColor={RED_100}>
          {tag?.toUpperCase()}
        </Tag>
        <Ul>
          {experts?.map((item, index) => {
            return (
              <li key={index}>
                <ExpandButton
                  type="button"
                  onClick={() => setShowExpert(index)}
                  aria-expanded={showExpert === index}
                  aria-controls={`expert-details-${index}`}
                  aria-labelledby={`expert-name-${index}`}
                >
                  <Figure>
                    <ImgWrapper>
                      <Icons.Expand $color="NEUTRAL_WHITE" $size="sm" />
                      <img
                        src={item?.image?.node.mediaItemUrl ?? undefined}
                        alt={item?.image?.node.altText ?? ''}
                      />
                    </ImgWrapper>
                    <figcaption id={`expert-name-${index}`}>
                      <ExpertName>{item?.name}</ExpertName>
                      <P>{item?.profession}</P>
                    </figcaption>
                  </Figure>
                </ExpandButton>
              </li>
            );
          })}
        </Ul>
        <Expert
          ref={expertCardRef}
          id={`expert-details-${showExpert ? showExpert : ''}`}
          $show={showExpert !== null}
        >
          <CornerButtonWrapper>
            <Button
              onDark={isMobile}
              type="button"
              onClick={() => setShowExpert(null)}
              configuration={isMobile ? 'toned' : 'text'}
              icon={<Icons.Close $size="sm" $color={isMobile ? 'NEUTRAL_WHITE' : 'GREY_1000'} />}
              aria-label="Close expert detials card"
            />
          </CornerButtonWrapper>
          {showExpert !== null &&
            experts &&
            experts.length &&
            experts[showExpert]?.image?.node.mediaItemUrl && (
              <ExpertImgWrapper>
                <img
                  src={experts[showExpert]?.image?.node.mediaItemUrl}
                  alt={experts[showExpert]?.image?.node.altText ?? ''}
                />
              </ExpertImgWrapper>
            )}
          <ExpertDetails>
            {showExpert !== null && experts && experts.length && (
              <>
                <ExpertDetailsHeader>
                  <ExpertHeader>{experts[showExpert]?.name}</ExpertHeader>
                  <ExpertWorkTitle>{experts[showExpert]?.profession}</ExpertWorkTitle>
                </ExpertDetailsHeader>
                <Text as="p" variant="body">
                  {experts[showExpert]?.description}
                </Text>
              </>
            )}
          </ExpertDetails>
        </Expert>
      </Wrapper>
    </Section>
  );
};
