'use client';

import { Honeybadger } from '@honeybadger-io/react';
import { useEffect, useRef } from 'react';

export const MissingModule = ({
  typename,
  details,
}: {
  typename: string;
  details?: Record<string, any> | null;
}) => {
  const firedRef = useRef<string | null>(null);
  useEffect(() => {
    if (firedRef.current === typename) return;

    Honeybadger.notify(`Missing module implementation for ${typename} at ${window.location.href}`, {
      params: details ?? undefined,
    });
    firedRef.current = typename;
  }, [typename]);
  return null;
};
