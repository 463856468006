'use client';

import { toBoolean } from '@mnd-frontend/ts';
import { MEDIA_LARGE, RED_100, RED_700, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import styled from 'styled-components';
import { Author } from '../patterns/author';
import { P, Tag } from '../ui';

const Quote = styled.span`
  margin-top: 0;
  font-size: 5rem;
  line-height: 0.75;
`;

const Blockquote = styled.blockquote`
  display: flex;
  column-gap: 1rem;
  font-weight: 600;
  color: ${RED_700};
  font-size: 1.25rem;
  margin: 4rem 0 0 0;
`;

const H3 = styled.h3`
  display: flex;
  opacity: 0.85;
  row-gap: 1rem;
  font-size: 1.45rem;
  flex-direction: column;
  margin: 0 0 1.5rem 0;
`;

const Row = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  &:nth-child(even) {
    grid-template-columns: 1fr auto;
  }
  gap: ${SPACING_12};
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    display: flex;
    flex-direction: column;
    &:nth-child(odd) {
      flex-direction: column-reverse;
    }
  }
`;

const Authors = styled.ul`
  display: flex;
  flex-direction: column;

  gap: ${SPACING_12};
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
  }
`;

const Container = styled.div`
  display: flex;
  row-gap: 5rem;
  margin: 0 auto;
  max-width: 1280px;
  flex-direction: column;
`;

const Section = styled.section`
  padding: 0;
  margin: 0;
  background-color: ${RED_100};
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionFourChapterThree: React.FC<{
  tag: string | null;
  header: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChThreeSectionFourDescription';
        descriptionParagraph: string | null;
      } | null)[]
    | null;
  quotes:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChThreeSectionFourQuotes';
        authorName: string | null;
        authorDescription: string | null;
        authorSize: string | null;
        authorQuote: string | null;
        reversePosition: Array<string | null> | null;
        authorImage: {
          __typename?: 'AcfMediaItemConnectionEdge';
          node: {
            __typename?: 'MediaItem';
            altText: string | null;
            mediaItemUrl: string | null;
          };
        } | null;
      } | null)[]
    | null;
}> = ({ tag, header, description, quotes }) => {
  const filteredQuotes = quotes?.filter(toBoolean) ?? [];
  return (
    <Section>
      <Container>
        <div>
          <H3>
            <Tag $color={RED_100} $backgroundColor={RED_700}>
              {tag?.toUpperCase()}
            </Tag>
            {header}
          </H3>
          {description?.map((item, index) => <P key={index}>{item?.descriptionParagraph}</P>)}
        </div>
        <Authors>
          {filteredQuotes.map((item, index) => (
            <Row key={index}>
              {item.reversePosition?.length && item.reversePosition[0] === 'reverse' ? (
                <>
                  <Blockquote>
                    <Quote>“</Quote>
                    <i>{item.authorQuote}</i>
                  </Blockquote>
                  <Author
                    imageUrl={item.authorImage?.node.mediaItemUrl}
                    authorName={item.authorName}
                    authorDescription={item.authorDescription}
                    sticky={false}
                    square
                  />
                </>
              ) : (
                <>
                  <Author
                    imageUrl={item.authorImage?.node.mediaItemUrl}
                    authorName={item.authorName}
                    authorDescription={item.authorDescription}
                    sticky={false}
                    square={filteredQuotes.length > 1}
                  />
                  <Blockquote>
                    <Quote>“</Quote>
                    <i>{item.authorQuote}</i>
                  </Blockquote>
                </>
              )}
            </Row>
          ))}
        </Authors>
      </Container>
    </Section>
  );
};
