import { ChapterThreeFragment } from '../../../../../../../wp-generated/types';
import { Footer, Header } from '../navigation';
import { Chapter, Takeaways } from '../patterns';
import { SectionFiveChapterThree } from './section-five-chapter-three';
import { SectionFourChapterThree } from './section-four-chapter-three';
import { SectionThreeChapterThree } from './section-three-chapter-three';
import { SectionTwoChapterThree } from './section-two-chapter-three';

export const ChapterThree = ({
  moduleData,
  locale,
}: {
  moduleData: ChapterThreeFragment;
  locale?: string;
}) => {
  return (
    <>
      <Header
        navigation={moduleData.navigationItems}
        downloadableText={moduleData.downloadableText}
        downloadableUrl={moduleData.downloadableUrl}
      />
      <article>
        <Chapter
          subheader={moduleData.prChThreeChapterSubheader}
          header={moduleData.prChThreeChapterHeader}
          tag={moduleData.prChThreeSectionOneTag}
          description={moduleData.prChThreeSectionOneDescription}
          locale={locale}
        />
        <SectionTwoChapterThree
          header={moduleData.prChThreeSectionTwoHeader}
          description={moduleData.prChThreeSectionTwoDescription}
          stats={moduleData.prChThreeSectionTwoStats}
        />
        <SectionThreeChapterThree
          header={moduleData.prChThreeSectionThreeHeader}
          description={moduleData.prChThreeSectionThreeDescription}
          blueStats={moduleData.prChThreeSectionThreeBlueStats}
          redStats={moduleData.prChThreeSectionThreeRedStats}
        />
        <SectionFourChapterThree
          tag={moduleData.prChThreeSectionFourTag}
          header={moduleData.prChThreeSectionFourHeader}
          description={moduleData.prChThreeSectionFourDescription}
          quotes={moduleData.prChThreeSectionFourQuotes}
        />
        <SectionFiveChapterThree
          tag={moduleData.prChThreeSectionFiveTag}
          header={moduleData.prChThreeSectionFiveHeader}
          description={moduleData.prChThreeSectionFiveDescription}
          cardDescription={moduleData.prChThreeSectionFiveCardDescription}
          quote={moduleData.prChThreeSectionFiveQuote}
          authorImage={moduleData.prChThreeSectionFiveAuthorImage?.node}
          authorName={moduleData.prChThreeSectionFiveAuthorName}
          authorVideo={moduleData.prChThreeSectionFiveAuthorVideo}
          authorVideoPlayText={moduleData.prChThreeSectionFiveAuthorVideoPlayText}
          authorDescription={moduleData.prChThreeSectionFiveAuthorDescription}
          list={moduleData.prChThreeSectionFiveParagraphs}
        />
        <Takeaways
          subheader={moduleData.prChThreeTakeawaySubheader}
          title={moduleData.prChThreeTakeawayTitle}
          list={moduleData.prChThreeTakeawayItems}
        />
      </article>
      <Footer
        subheader={moduleData.prChThreeFooterSubheader}
        header={moduleData.prChThreeFooterHeader}
        link={moduleData.link}
      />
    </>
  );
};
