import { ChapterFourFragment } from '../../../../../../../wp-generated/types';
import { Footer, Header } from '../navigation';
import { Chapter, Takeaways } from '../patterns';
import { SectionEightChapterFour } from './section-eight-chapter-four';
import { SectionFiveChapterFour } from './section-five-chapter-four';
import { SectionFourChapterFour } from './section-four-chapter-four';
import { SectionNineChapterFour } from './section-nine-chapter-four';
import { SectionSevenChapterFour } from './section-seven-chapter-five';
import { SectionSixChapterFour } from './section-six-chapter-four';
import { SectionThreeChapterFour } from './section-three-chapter-four';
import { SectionTwoChapterFour } from './section-two-chapter-four';

export const ChapterFour = ({
  moduleData,
  locale,
}: {
  moduleData: ChapterFourFragment;
  locale?: string;
}) => {
  return (
    <>
      <Header
        navigation={moduleData.navigationItems}
        downloadableText={moduleData.downloadableText}
        downloadableUrl={moduleData.downloadableUrl}
      />
      <article>
        <Chapter
          subheader={moduleData.prChFourChapterSubheader}
          header={moduleData.prChFourChapterHeader}
          tag={moduleData.prChFourSectionOneTag}
          description={moduleData.prChFourSectionOneDescription}
          locale={locale}
        />
        <SectionTwoChapterFour
          header={moduleData.prChFourSectionTwoHeader}
          description={moduleData.prChFourSectionTwoDescription}
          stats={moduleData.prChFourSectionTwoStats}
        />
        <SectionThreeChapterFour
          header={moduleData.prChFourSectionThreeHeader}
          description={moduleData.prChFourSectionThreeDescription}
          stats={moduleData.prChFourSectionThreeStats}
          redStats={moduleData.prChFourSectionThreeRedStats}
        />
        <SectionFourChapterFour
          header={moduleData.prChFourSectionFourHeader}
          description={moduleData.prChFourSectionFourDescription}
          stats={moduleData.prChFourSectionFourRedStats}
          redHeader={moduleData.prChFourSectionFourRedHeader}
        />
        <SectionFiveChapterFour
          header={moduleData.prChFourSectionFiveHeader}
          description={moduleData.prChFourSectionFiveDescription}
          blueStats={moduleData.prChFourSectionFiveBlueStats}
          redStats={moduleData.prChFourSectionFiveRedStats}
        />
        <SectionSixChapterFour
          header={moduleData.prChFourSectionSixHeader}
          description={moduleData.prChFourSectionSixDescription}
          stats={moduleData.prChFourSectionSixStats}
          chart={moduleData.prChFourSectionSixChart}
          chartHeader={moduleData.prChFourSectionSixChartHeader}
        />
        <SectionSevenChapterFour
          tag={moduleData.prChFourSectionSevenTag}
          header={moduleData.prChFourSectionSevenHeader}
          description={moduleData.prChFourSectionSevenDescription}
          quote={moduleData.prChFourSectionSevenQuote}
          authorImage={moduleData.prChFourSectionSevenAuthorImage?.node}
          authorName={moduleData.prChFourSectionSevenAuthorName}
          authorDescription={moduleData.prChFourSectionSevenAuthorDescription}
        />
        <SectionEightChapterFour
          tag={moduleData.prChFourSectionEightTag}
          header={moduleData.prChFourSectionEightHeader}
          description={moduleData.prChFourSectionEightDescription}
          quote={moduleData.prChFourSectionEightQuote}
          authorImage={moduleData.prChFourSectionEightAuthorImage?.node}
          authorName={moduleData.prChFourSectionEightAuthorName}
          authorDescription={moduleData.prChFourSectionEightAuthorDescription}
          list={moduleData.prChFourSectionEightParagraphs}
        />
        <SectionNineChapterFour
          subheader={moduleData.prChFourSectionNineSubheader}
          header={moduleData.prChFourSectionNineHeader}
          list={moduleData.prChFourSectionNineParagraphs}
        />
        <Takeaways
          subheader={moduleData.prChFourTakeawaySubheader}
          title={moduleData.prChFourTakeawayTitle}
          list={moduleData.prChFourTakeawayItems}
        />
      </article>
      <Footer
        subheader={moduleData.prChFourFooterSubheader}
        header={moduleData.prChFourFooterHeader}
        link={moduleData.link}
      />
    </>
  );
};
