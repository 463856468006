'use client';

import { toBoolean } from '@mnd-frontend/ts';
import { MEDIA_LARGE, NEUTRAL_WHITE, RED_100, RED_700, SPACING_3 } from '@mnd-frontend/ui';
import { styled } from 'styled-components';
import { P, Tag } from '../ui';

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1280px;
  row-gap: ${SPACING_3};
  flex-direction: column;
`;

const SectionHeader = styled.h4`
  line-height: 1;
  font-size: 2rem;
  margin: 0;
`;

const H5 = styled.h5`
  margin: 0;
  font-size: 1.15rem;
`;

const HeaderParagraphPairing = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const Section = styled.section`
  background-color: ${NEUTRAL_WHITE};
  @media (min-width: ${MEDIA_LARGE}px) {
    margin-top: -10rem;
    padding: 10rem 5rem 0 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem 0 2rem;
  }
`;

export const SectionThreeIntro: React.FC<{
  tag: string | null;
  header: string | null;
  list:
    | ({
        __typename?: 'ModulesFlexibleModulesPrInSectionThreeList';
        header: string | null;
        description: string | null;
      } | null)[]
    | null;
}> = ({ tag, header, list }) => {
  return (
    <Section>
      <Container>
        <Tag as="h3" $color={RED_700} $backgroundColor={RED_100}>
          {tag?.toUpperCase()}
        </Tag>
        <SectionHeader>{header}</SectionHeader>
        {list?.filter(toBoolean).map((item, index) => (
          <HeaderParagraphPairing key={index}>
            <H5>{item.header}</H5>
            <P>{item.description}</P>
          </HeaderParagraphPairing>
        ))}
      </Container>
    </Section>
  );
};
