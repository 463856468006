'use client';

import { MEDIA_LARGE, MEDIA_SMALL, NEUTRAL_WHITE } from '@mnd-frontend/ui';
import styled, { css } from 'styled-components';
import { PrReportVideo } from './video';

const Info = styled.div``;
const AuthorDescription = styled.div`
  line-height: 1.2;
`;

const AuthorName = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5;
`;

const AuthorStyles = styled.div<{
  $imageUrl: string;
  $square: boolean;
  $withQuote: boolean;
}>`
  display: flex;
  padding: 2rem;
  justify-content: end;
  flex-direction: column;

  background:
    ${props =>
      props.$withQuote
        ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)'
        : 'linear-gradient(180deg, rgba(0, 0, 0, 0) 49.77%, rgba(0, 0, 0, 0.5) 100%)'},
    url(${props => props.$imageUrl}) lightgray 50% / cover no-repeat;
  aspect-ratio: ${props => (props.$square ? '1 / 1' : '3 / 4')};
  width: ${props => (props.$square ? '250px' : '400px')};
  max-width: 100%;
`;

const Blockquote = styled.blockquote`
  text-align: center;
  margin: 0;
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.4;
  > span {
    position: relative;
    &:before {
      content: '“';
      font-size: 4rem;
      line-height: 0;
      left: 0;
      position: absolute;
      top: -0.25rem;
    }
  }

  @media (max-width: ${MEDIA_SMALL}px) {
    font-size: 1.125rem;
  }
`;

const Card = styled.div`
  overflow: hidden;
  position: relative;
  height: fit-content;
  border-radius: 24px;
  color: ${NEUTRAL_WHITE};
`;

const Wrapper = styled.div<{ $sticky: boolean }>`
  margin: 0 auto;
  display: flex;
  row-gap: 1.5rem;
  overflow: hidden;
  height: fit-content;
  max-width: 100%;
  flex-direction: column;
  ${props =>
    props.$sticky &&
    css`
      @media (min-width: ${MEDIA_LARGE}px) {
        position: sticky;
        top: calc(30% - 86.5px);
      }
    `}
`;

export const Author = ({
  authorName,
  authorDescription,
  imageUrl,
  sticky = true,
  square = false,
  quote,
  authorVideo,
  playText,
  description,
}: {
  authorName?: string | null;
  imageUrl?: string | null;
  authorDescription?: string | null;
  sticky?: boolean;
  square?: boolean;
  quote?: string | null;
  authorVideo?: string | null;
  playText?: string | null;
  description?:
    | ({
        paragraph: string | null;
        paragraphBold: Array<string | null> | null;
      } | null)[]
    | null;
}) => {
  return authorVideo ? (
    <PrReportVideo
      embedCode={authorVideo}
      author={authorName}
      authorDescription={authorDescription}
      imageUrl={imageUrl}
      playText={playText}
      description={description}
    />
  ) : (
    <Wrapper $sticky={sticky}>
      <Card>
        <AuthorStyles $imageUrl={imageUrl ?? ''} $square={square} $withQuote={!!quote}>
          {quote ? (
            <Blockquote>
              <span>{quote}</span>
            </Blockquote>
          ) : null}
          <Info>
            <AuthorName>{authorName}</AuthorName>
            <AuthorDescription>{authorDescription}</AuthorDescription>
          </Info>
        </AuthorStyles>
      </Card>
    </Wrapper>
  );
};
