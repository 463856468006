'use client';

import { MEDIA_LARGE, RED_100, SIZING_3, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import { styled } from 'styled-components';
import { H4, Ol, OlLi, P } from '../ui';

const H3 = styled.h3`
  font-size: ${SIZING_3};
  font-weight: 700;
  margin: 0;
`;

const Wrapper = styled.div`
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
`;

const Inner = styled.div`
  display: flex;
  row-gap: 3rem;
  padding: 3rem;
  border-radius: 24px;
  flex-direction: column;
  background-color: ${RED_100};
`;

const Article = styled.div`
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  height: fit-content;
  @media (min-width: ${MEDIA_LARGE}px) {
    position: sticky;
    top: calc(30% - 86.5px);
  }
`;

const Container = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Section = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionSixSummary: React.FC<{
  header: string | null;
  itemHeader: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrSuSectionSixDescription';
        descriptionParagraph: string | null;
      } | null)[]
    | null;
  list:
    | ({
        __typename?: 'ModulesFlexibleModulesPrSuSectionSixItems';
        header: string | null;
        description: string | null;
      } | null)[]
    | null;
}> = ({ header, itemHeader, description, list }) => {
  return (
    <Section>
      <Container>
        <Article>
          <H3>{header}</H3>
          {description?.map((item, index) => <P key={index}>{item?.descriptionParagraph}</P>)}
        </Article>
        <Inner>
          <P>{itemHeader}</P>
          <Ol>
            {list?.map((item, index) => (
              <OlLi key={index}>
                <Wrapper>
                  <H4>{item?.header}</H4>
                  <P>{item?.description}</P>
                </Wrapper>
              </OlLi>
            ))}
          </Ol>
        </Inner>
      </Container>
    </Section>
  );
};
