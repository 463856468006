import styled from 'styled-components';
import { VideoFragment } from '../../../wp-generated/types';
import { moduleBackgroundToCSS } from '../getModuleColors';
import { Video } from '../Video';

const Container = styled.section<{ $background: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 var(--site-padding) var(--spacing-x-large);
  margin: var(--section-padding) 0 0;
  z-index: 1;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    top: auto;
    width: 100vw;
    height: calc(min(92vw, 675px) * 0.5625 * 0.8 + var(--spacing-large));
    clip-path: ellipse(100% 100% at 50% 100%);
    background: ${({ $background }) => $background};
    z-index: -1;
  }
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: var(--font-weight-extra-bold);
  letter-spacing: 1px;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: var(--spacing-large);
  text-align: center;
`;

const VideoContainer = styled.div`
  position: relative;
  border: var(--spacing-medium) solid var(--color-deep-purple);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  max-width: calc(640px + 2 * var(--spacing-medium));
  max-height: calc(360px + 2 * var(--spacing-medium));
  overflow: hidden;
`;

const VideoModule = ({ moduleData }: { moduleData: VideoFragment }) => {
  return (
    <Container $background={moduleBackgroundToCSS(moduleData.bgColor)}>
      <Title>{moduleData.title}</Title>
      <VideoContainer>
        <Video title={moduleData.title ?? ''} embedCode={moduleData.code ?? ''} />
      </VideoContainer>
    </Container>
  );
};

export default VideoModule;
