'use client';
import { Icons, RED_700, RED_800, SIZING_4, useToasts } from '@mnd-frontend/ui';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { SrOnly } from '../../../../../../../components/SrOnly';
import { track } from '../../../../../../../lib/tracker';

const shares = {
  copyLink: {
    icon: <Icons.Link $size="xxs" $color="NEUTRAL_WHITE" />,
    label: 'Copy link',
    asLink: false,
  },
  facebook: {
    icon: <Icons.Facebook $size="xxs" $color="NEUTRAL_WHITE" />,
    label: 'Facebook',
    asLink: true,
  },

  linkedin: {
    icon: <Icons.LinkedIn $size="xxs" $color="NEUTRAL_WHITE" />,
    label: 'LinkedIn',
    asLink: true,
  },
  x: {
    icon: <Icons.X $size="xxs" $color="NEUTRAL_WHITE" />,
    label: 'X (Twitter)',
    asLink: true,
  },
};

const BtnAndLink = css`
  width: ${SIZING_4};
  height: ${SIZING_4};
  border-radius: 50%;
  background-color: ${RED_700};
  display: grid;
  place-items: center;
  cursor: pointer;
`;

const Btn = styled.button`
  ${BtnAndLink}

  &:hover {
    background-color: ${RED_800};
  }
  &:disabled {
    background-color: ${RED_700};
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Link = styled.a`
  ${BtnAndLink}
  text-decoration: none;
  &:hover {
    background-color: ${RED_800};
  }
`;

const Container = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 0.5rem;
`;

const BASE_PATH = 'https://www.mynewsdesk.com';

const Share = ({ chapterTitle }: { chapterTitle?: string | null }) => {
  const { t } = useTranslation();
  const { showFailureToast } = useToasts();
  const [isCopied, setIsCopied] = useState(false);
  const pathname = usePathname();
  const mndUrl = `${BASE_PATH}${pathname}`;

  const handleTracking = (method: string) => {
    track({
      event: 'share_content_intention',
      traits: {
        event_info: {
          article_id: pathname,
          article_name: chapterTitle ?? 'PR Insights',
          share_method: method,
          position: 'top',
        },
      },
    });
  };

  const handleCopyPath = async () => {
    handleTracking('copy_link');
    try {
      await navigator.clipboard.writeText(mndUrl);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
      alert('Link copied to clipboard');
    } catch (_) {
      showFailureToast('Could not copy path to clipboard');
    }
  };

  return (
    <Container>
      {Object.entries(shares).map(([key, { icon, label, asLink }]) => {
        let shareLink = '';

        switch (key) {
          case 'linkedin':
            shareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(mndUrl)}`;
            break;
          case 'x':
            shareLink = `https://x.com/intent/tweet?url=${encodeURIComponent(mndUrl)}&text=${encodeURIComponent(chapterTitle ?? 'PR Insights')}`;
            break;
          case 'facebook':
            shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(mndUrl)}`;
            break;
          default:
            shareLink = '';
            break;
        }
        return (
          <li key={key}>
            {asLink ? (
              <Link
                href={shareLink}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={t('pr_insights_share_link', { some: label })}
                onClick={() => handleTracking(label)}
              >
                {icon}
                <SrOnly as="span">{t('pr_insights_share_link', { some: label })}</SrOnly>
              </Link>
            ) : (
              <Btn
                onClick={handleCopyPath}
                disabled={isCopied}
                aria-label={t('pr_insights_copy_to_clipboard')}
              >
                {icon}
                <SrOnly as="span">{t('pr_insights_copy_to_clipboard')}</SrOnly>
              </Btn>
            )}
          </li>
        );
      })}
    </Container>
  );
};

export default Share;
