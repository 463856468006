'use client';

import { MEDIA_LARGE, SIZING_3, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import styled from 'styled-components';
import { Author } from '../patterns/author';
import { P } from '../ui';

const H3 = styled.h3`
  font-size: ${SIZING_3};
  font-weight: 700;
  margin: 0;
`;

const Article = styled.div`
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
`;

const Container = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: auto 1fr;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    max-width: 100%;
    gap: ${SPACING_8};
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
  }
`;

const Section = styled.section`
  row-gap: 1rem;
  display: flex;
  flex-direction: column;
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionFiveSummary: React.FC<{
  header: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrSuSectionFiveDescription';
        descriptionParagraph: string | null;
      } | null)[]
    | null;
  quote: string | null;
  authorImage:
    | {
        __typename?: 'MediaItem';
        altText: string | null;
        mediaItemUrl: string | null;
      }
    | undefined;
  authorName: string | null;
  authorDescription: string | null;
}> = ({ header, description, quote, authorImage, authorName, authorDescription }) => {
  return (
    <Section>
      <Container>
        <Author
          imageUrl={authorImage?.mediaItemUrl ?? ''}
          authorName={authorName}
          authorDescription={authorDescription}
          quote={quote}
        />
        <Article>
          <H3>{header}</H3>
          {description?.map((item, index) => <P key={index}>{item?.descriptionParagraph}</P>)}
        </Article>
      </Container>
    </Section>
  );
};
