'use client';

import { MEDIA_LARGE } from '@mnd-frontend/ui';
import { styled } from 'styled-components';
import { H2, P } from '../ui';
import { DualStat } from '../ui/dual-stat';

const Article = styled.div`
  display: flex;
  row-gap: 2rem;
  flex-direction: column;
  max-height: fit-content;
`;

const Container = styled.div`
  display: flex;
  row-gap: 2rem;
  margin: 0 auto;
  max-width: 1280px;
  flex-direction: column;
`;

const Section = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionFiveChapterFour: React.FC<{
  header: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFourSectionFiveDescription';
        descriptionParagraph: string | null;
      } | null)[]
    | null;
  blueStats:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFourSectionFiveBlueStats';
        percentage: number | null;
        description: Array<{
          __typename?: 'ModulesFlexibleModulesPrChFourSectionFiveBlueStatsDescription';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
  redStats:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFourSectionFiveRedStats';
        percentage: number | null;
        description: Array<{
          __typename?: 'ModulesFlexibleModulesPrChFourSectionFiveRedStatsDescription';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
}> = ({ header, description, blueStats, redStats }) => {
  return (
    <Section>
      <Container>
        <Article>
          <H2>{header}</H2>
          {description?.map((item, index) => <P key={index}>{item?.descriptionParagraph}</P>)}
        </Article>
        <DualStat blueStats={blueStats} redStats={redStats} />
      </Container>
    </Section>
  );
};
